<template>
  <b-modal
    id="deactive-card"
    size="sm"
    centered
    hide-header
    cancel-variant="none"
    ok-title="Ngừng hoạt động"
    cancel-title="Quay lại"
    ok-variant="danger"
    @ok="activeAction_UME02()"
  >
    <b-card-header>
      <div class="d-flex justify-content-between align-items-center block-header">
        <p class="mb-0 title-modal color-primary">{{$t('golf_common_deactive')}}</p>
        <feather-icon
          icon="XIcon"
          size="24"
          class="cursor-pointer color-primary"
          @click="hideModal"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <p class="mb-0 pt-2 pb-2 text">{{$t('golf_member_card_sure_deactive')}}</p>
    </b-card-body>
  </b-modal>
</template>

<script>
import {
  BCardHeader,
  BCardBody
} from "bootstrap-vue";
import {memberCard} from '@/api/member-card';

export default {
  props: [
    'item'
  ],
  components: {
    BCardHeader, BCardBody
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('deactive-card')
    },
    async activeAction_UME02() {
      const response = await memberCard.deActiveCard({
        MemberCard: {
          Id: this.item.Id,
        }
      })
      if (response.Status === '200') {
        this.showResToast(response)
        this.$emit('event', {type: 'action_success'})
      } else {
        this.showResToast(response)
      }
    }
  }
}
</script>

<style lang="scss">
#deactive-card {
  .card-header {
      .color-primary {
        color: #EA5455;
      }
      background: rgba(234, 84, 85, 0.12);
      border-radius: 6px 6px 0px 0px;
      padding: 0 18px;
      .title-modal {
        padding: 10px 0;
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
        height: 48px;display: flex;
        align-items: center;
      }
      .text {
        color: #5D5F65;
        font-size: 12px;
        line-height: 18px;
      }
  }
}
</style>
