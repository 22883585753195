<template>
  <b-modal
    id="active-card"
    size="sm"
    centered
    hide-header
    cancel-variant="none"
    ok-title="Kích hoạt lại"
    cancel-title="Quay lại"
    ok-variant="primary"
    @ok="activeAction_UME03()"
  >
    <b-card-header class="banned-background-color">
      <div class="d-flex justify-content-between align-items-center block-header">
        <p class="mb-0 title-modal color-primary">{{$t('golf_common_active')}}</p>
        <ez-icon @click="hideModal" icon="ezGolf-icon-x" :color="`#114A9F`" class="cursor" :size="24"/>
      </div>
    </b-card-header>
    <b-card-body>
      <p class="mb-0 pt-2 pb-2 text">{{$t('golf_member_card_reActive')}}</p>
    </b-card-body>
  </b-modal>
</template>

<script>
import {memberCard} from '@/api/member-card';

export default {
  props: [
    'item'
  ],
  methods: {
    hideModal() {
      this.$bvModal.hide('active-card')
    },
    async activeAction_UME03() {
      const response = await memberCard.activeCard({
        MemberCard: {
          Id: this.item.Id,
        }
      })

      if (response.Status === '200') {
        this.showResToast(response)
        this.$emit('event', {type: 'action_success'})
      } else {
        this.showResToast(response)
      }
    }
  }
}
</script>

<style lang="scss">
#active-card {
  .banned-background-color {
    .color-primary {
      color: #114A9F;
    }
    background: rgba(17, 74, 159, 0.12) !important;
    border-radius: 6px 6px 0px 0px;
    padding: 0;
    .block-header {
      height: 48px;
      padding: 0 18px;
    }
    .title-modal {
      padding: 10px 0;
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    .text {
      color: #5D5F65;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
</style>
