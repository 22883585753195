import { memberCard } from '@/api/member-card'
import { courseSetting } from '@/api/course-setting'
import { golfClass } from '@/api/golf-class'
import vSelect from 'vue-select'
import { required } from '@validations'
import 'devextreme/dist/css/dx.light.css'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  AlertCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MoreVerticalIcon,
  PlusCircleIcon,
  SearchIcon,
  TrashIcon,
  XIcon,
} from 'vue-feather-icons'
import SidebarContent from './SidebarContent'
import DeleteCard from './DeleteCard.vue'
import ActiveCard from './ActiveCard.vue'
import DeActiveCard from './DeActiveCard.vue'
const dataGridRef = 'table-member'
export default {
  name: 'Member-card',
  components: {
    SidebarContent,
    DeleteCard,
    ActiveCard,
    DeActiveCard,
    required,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    ChevronDownIcon,
    SearchIcon,
    CheckIcon,
    XIcon,
    ChevronUpIcon,
    PlusCircleIcon,
    MoreVerticalIcon,
    AlertCircleIcon,
    TrashIcon,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
  },
  data() {
    return {
      submitted: false,
      searchKey: '',
      rows: 100,
      currentPage: 1,
      exportStyle: null,
      exportOptions: [
        { value: null, text: this.$t('golf_common_export') },
        { value: 'Excel', text: 'Xuất Excel' },
        // { value: 2, text: 'Xuất CSV' },
        // { value: 1, text: 'Xuất PDF' },
      ],
      courseList: [],
      courseSelected: [],
      tabs: true,
      listGolfClass: [],
      page: 5,
      pages: [
        { value: null, text: 'Chọn' },
        { value: '5', text: 5 },
        { value: '10', text: 10 },
        { value: '15', text: 15 },
        { value: '20', text: 20 },
      ],
      fields: [
        { key: 'CardTypeCode', label: this.$t('golf_member_card_code'), sortable: true },
        { key: 'CardTypeName', label: this.$t('golf_member_card_name'), sortable: true },
        { key: 'Description', label: this.$t('golf_member_card_description'), sortable: true },
        { key: 'TotalAmount', label: this.$t('golf_member_card_total_amount'), sortable: true },
        { key: 'yard_applied', label: this.$t('golf_member_card_yard_applied'), sortable: false },
        { key: 'GolfClassId', label: this.$t('golf_member_card_golfclass'), sortable: false },
        { key: 'NumberOfPlay', label: this.$t('golf_member_card_turn'), sortable: true },
        { key: 'NumberOfRound', label: this.$t('golf_member_card_total_round'), sortable: true },
        { key: 'NumberOfDay', label: this.$t('golf_member_card_day_use'), sortable: true },
        { key: 'action', label: '' },
      ],
      configTable: [
        {
          caption: this.$t('golf_member_card_code'),
          field: 'CardTypeCode',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: this.$t('golf_member_card_name'),
          field: 'CardTypeName',
          isShow: true,
          alignment: 'center',
          width: '15%',
          cellTemplate: 'CardTypeName'
        },
        {
          caption: this.$t('golf_member_card_description'),
          field: 'Description',
          isShow: true,
          alignment: 'center',
          width: '10%',
          cellTemplate: 'Description'
        },
        {
          caption: this.$t('golf_member_card_total_amount'),
          field: 'TotalAmount',
          isShow: true,
          alignment: 'center',
          width: '10%',
          cellTemplate: 'TotalAmount'
        },
        {
          caption: this.$t('golf_member_card_yard_applied'),
          field: 'CourseId',
          isShow: true,
          alignment: 'center',
          width: '10%',
          cellTemplate: 'Course'
        },
        {
          caption: this.$t('golf_member_card_golfclass'),
          field: 'GolfClassId',
          isShow: true,
          cellTemplate: 'GolfClass',
          alignment: 'center',
          width: '10%',
        },
        {
          caption: this.$t('golf_member_card_turn'),
          field: 'NumberOfPlay',
          isShow: true,
          alignment: 'center',
          width: '10%',
          cellTemplate: 'NumberOfPlay'
        },
        {
          caption: this.$t('golf_member_card_total_round'),
          field: 'NumberOfRound',
          isShow: true,
          alignment: 'center',
          width: '10%',
          cellTemplate: 'NumberOfRound'
        },
        {
          caption: this.$t('golf_member_card_day_use'),
          field: 'NumberOfDay',
          isShow: true,
          alignment: 'center',
          width: '10%',
          cellTemplate: 'NumberOfDay'
        },
        {
          caption: '',
          field: 'action',
          isShow: true,
          cellTemplate: 'action',
          alignment: 'center',
          width: '5%',
        }
      ],
      items: [],
      actionData: null,
      MemberCard: null,
      formatFields: [
        'TotalAmount',
        'NumberOfPlay',
        'NumberOfRound',
        'NumberOfDay',
      ],
      perPage: 50,
      optionPerPage: [10, 50, 100],
      isLoading: false
    }
  },
  computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
  },
  async created() {
    this.refreshData()
    await this.getListCourse()
    await this.getGolfClass()
    await this.getListMemberCard_RMEC01()
  },
  methods: {
    handleEvent(result) {
      switch (result.type) {
        case 'save_success':
        case 'action_success':
          this.getListMemberCard_RMEC01()
          break
        case 'deleteCard':
          this.handleAction(result.MemberCard, 'delete-card')
          this.toggleSidebar()
          break
        case 'activeCard':
          this.handleAction(result.MemberCard, 'active-card')
          this.toggleSidebar()
          break
        case 'deActiveCard':
          this.handleAction(result.MemberCard, 'deactive-card')
          this.toggleSidebar()
          break
      }
    },
    async getListCourse() {
      const response = await courseSetting.getListCourse()
      if (response) {
        this.courseList = response?.Data?.Course
        this.courseSelected.push(response?.Data?.Course[0])
      }
    },
    async myRowClickHandler(record, index) {
      await this.getDetailCard_RMEC02(record.data.Id)
    },
    async getGolfClass() {
      const CourseId = this.courseSelected.map(item => item.Id)
      const response = await golfClass.getListGolfClass({ CourseId })
      if (response) {
        this.listGolfClass = response?.Data?.GolfClass
      }
    },
    async getListMemberCard_RMEC01() {
      this.isLoading = true
      const CourseId = this.courseSelected.map(item => item.Id)
      const KeyWord = this.searchKey
      const response = await memberCard.getListMemberCard({ CourseId, KeyWord })
      if (response) {
        response?.Data?.MemberCard.forEach(x => {
          x.GolfClassName = this.displayGolfClassCode(x.GolfClassId).GolfClassName
          var CourseNameTemp = ''
          x.CourseId.forEach(y => {
            CourseNameTemp += ' '+ this.courseList.find(z => z.Id == y).CourseName
          })
          x.CourseName = CourseNameTemp
        })
        this.items = response?.Data?.MemberCard
      }
      this.isLoading = false
    },
    async search() {
      await this.getGolfClass()
      await this.getListMemberCard_RMEC01()
    },
    showModal(id) {
      this.$bvModal.show(id)
    },
    showCreateForm(type) {
      this.refreshData()
      this.$root.$emit('bv::toggle::collapse', 'createCardSidebar')
    },
    refreshData() {
      this.MemberCard = {
        CardTypeCode: null,
        CardTypeName: null,
        Description: null,
        TotalAmount: 0,
        GolfClassId: null,
        NumberOfPlay: 1,
        NumberOfRound: 1,
        NumberOfDay: 1,
        NumberOfRound1Day: 1,
        IsTaxable: true,
        IsShare: true,
        IsShare4Group: false,
        WeeklyCode: [
          'MON',
          'TUE',
          'WED',
          'THU',
          'FRI',
          'SAT',
          'SUN',
        ],
        NumberOfPlayers1Day: null,
        NumberOfPlay1Day: null,
        MinNumberOfPlayersInGroup: null,
        MaxNumberOfPlayToShareGroup: null,
        CourseId: [],
        RateId: [],
        create: true,
      }
    },
    async getDetailCard_RMEC02(id) {
      const response = await memberCard.detailCard({
        MemberCard: {
          Id: id,
        },
      })
      if (response.Status == 200) {
        this.MemberCard = response?.Data?.MemberCard
        this.toggleSidebar()
      }
    },
    displayGolfClassCode(GolfClassId) {
      const response = this.listGolfClass.filter(x => x.Id == GolfClassId)
      if (response.length) {
        return response[0]
      }

      return {}
    },
    handleAction(item, id = null) {
      this.actionData = item
      if (id) {
        this.showModal(id)
      }
    },
    rowClass(item, type) {
      if (item && type === 'row' && item.InActive === true) {
        return 'isOver'
      }
    },
    toggleSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'createCardSidebar')
    },
    inActiveRow(e) {
      if (e.rowType !== 'data') { return }
      if (e.data.InActive) {
        e.rowElement.style.backgroundColor = 'rgba(193, 193, 193, 0.12)'
      }
    },
    viewDetail(item) {
      if (item.column && item.column.name != 'action') {
        if(item.data.Id) {
          this.getDetailCard_RMEC02(item.data.Id)
        }
      }
    },
    exportExcel() {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('MemberCard')
      exportDataGrid({
        component: this.dataGrid,
        worksheet,
        autoFilterEnabled: true,
        PrintSelectedRowsOnly: true,
        topLeftCell: { row: 4, column: 1 },
        customizeCell: function(options) {
          const { gridCell, excelCell } = options;
          if(gridCell.rowType === 'data') {
            if(gridCell.column.dataField == 'GolfClassId') {
              excelCell.value = gridCell.data.GolfClassName
            } else if(gridCell.column.dataField == 'CourseId') {
              excelCell.value = gridCell.data.CourseName
            }
          }
      }
      }).then(cellRange => {
        // header
        const headerRow = worksheet.getRow(2)
        headerRow.height = 30
        worksheet.mergeCells(2, 1, 2, 8)

        headerRow.getCell(1).value = 'Danh sách loại thẻ hội viên'
        headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }
        headerRow.getCell(1).alignment = { horizontal: 'center' }

        // footer
        const footerRowIndex = cellRange.to.row + 2
        const footerRow = worksheet.getRow(footerRowIndex)
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)
        footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true }
        footerRow.getCell(1).alignment = { horizontal: 'right' }
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'MemberCard.xlsx')
        })
      })
    }
  },
}
