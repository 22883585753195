import {golfClass} from "@/api/golf-class";
import vSelect from "vue-select";
import {required} from "@validations";
import {memberCard} from "@/api/member-card";
import flatPickr from 'vue-flatpickr-component';
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {
  ChevronDownIcon,
  SearchIcon,
  CheckIcon,
  XIcon,
  ChevronUpIcon,
  PlusCircleIcon,
  MoreVerticalIcon,
  AlertCircleIcon,
  TrashIcon,
  PauseCircleIcon,
  PlayIcon,
  ExternalLinkIcon
} from "vue-feather-icons";
import Cleave from 'vue-cleave-component'
import {booking} from "@/api/booking";
import dayjs from "dayjs";

export default {
  props: ["courseList", "MemberCard"],
  components: {
    ExternalLinkIcon,
    PlayIcon,
    PauseCircleIcon,
    required,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    ChevronDownIcon,
    SearchIcon,
    CheckIcon,
    XIcon,
    ChevronUpIcon,
    PlusCircleIcon,
    MoreVerticalIcon,
    AlertCircleIcon,
    TrashIcon,
    Cleave
  },
  data() {
    return {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      listWeeklyCode: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      isActive: null,
      TotalAmountFormat: null,
      submitted: false,
      listGolfClass: [],
      rates: {
        selected: null,
        list: []
      },
      selectedCourseId: [],
      selectedGolfClassId: [],
      selectedRateId: [],
      actionData: null
    };
  },
  watch: {
    selectedCourseId: {
      handler() {
        this.getGolfClass();
      },
    },
    selectedGolfClassId: {
      handler() {
        this.getRateCodes_RRA05();
      },
    },
    async MemberCard(data) {
      if (data.CourseId[0]) {
        this.selectedCourseId = this.courseList.filter((x) =>
            data.CourseId.includes(x.Id)
        );

        await this.getGolfClass()
        let selectedGolfClassId = this.listGolfClass.filter((x) =>
            data.GolfClassId == x.Id
        );

        if (selectedGolfClassId && selectedGolfClassId.length) {
          this.selectedGolfClassId = selectedGolfClassId[0]
        }

        await this.getRateCodes_RRA05()
        this.selectedRateId = this.rates.list.filter((x) =>
            data.RateId.includes(x.Id)
        );
      }

      if (data.TotalAmount) {
        this.TotalAmountFormat = data.TotalAmount
      }
    },
  },
  methods: {
    setActiveWeeklyCode(day) {
      return this.MemberCard.WeeklyCode.includes(day)
    },
    toogleWeeklyCode(day) {
      this.MemberCard.WeeklyCode = this.MemberCard.WeeklyCode.includes(day) ?
          this.MemberCard.WeeklyCode.filter(i => i !== day) : [...this.MemberCard.WeeklyCode, day];
    },
    async saveMemberCard() {
      const self = this;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          self.$refs.simpleRules.reset();
          await this.formatDataBeforSubmit();
          if (this.MemberCard.Id) {
            const mess = 'Thay đổi thẻ hội viên thành công';
            const response = await memberCard.updateCard({
              MemberCard: this.MemberCard,
            });

            this.showResToast(response, mess)
            this.$emit("event", {type: "save_success"});
            this.hideSidebar();
          } else {
            const mess = 'Tạo mới thẻ hội viên thành công';
            const response = await memberCard.createCard({
              MemberCard: this.MemberCard,
            });
            this.showResToast(response, mess)
          }
        }
      });
    },
    async formatDataBeforSubmit() {
      this.MemberCard.CourseId = this.selectedCourseId.map((item) => item.Id);
      this.MemberCard.RateId = this.selectedRateId.map((item) => item.Id);
      this.MemberCard.GolfClassId = this.selectedGolfClassId.Id;
      this.MemberCard.TotalAmount = Number(this.TotalAmountFormat);
      this.MemberCard.NumberOfPlay = Number(this.MemberCard.NumberOfPlay);
      this.MemberCard.NumberOfRound = Number(this.MemberCard.NumberOfRound);
      this.MemberCard.NumberOfDay = Number(this.MemberCard.NumberOfDay);
    },
    async getGolfClass() {
      const CourseId = this.selectedCourseId.map((item) => item.Id);
      const response = await golfClass.getListGolfClass({CourseId});
      if (response) {
        this.listGolfClass = response?.Data?.GolfClass;
      }
    },
    async getRateCodes_RRA05() {
      const CourseId = [];
      for (const course of this.selectedCourseId) {
        CourseId.push(course.Id)
      }

      const weeklyCode = this.listWeeklyCode[new Date().getDay()]
      const response = await booking.api_RRA05({
        // Date: dayjs(new Date()).format('YYYY-MM-DD'),
        CourseId: CourseId,
        GolfClassId: this.selectedGolfClassId.Id,
        // WeeklyCode: String(weeklyCode),
        // ClientId: this.data.BookingDetail.ClientId
      })

      if (response.Status === '200') {
        this.rates.selected = response?.Data?.Rate[0]
        this.rates.list = response?.Data?.Rate
      }
    },
    hideSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'createCardSidebar');
    },
    handleAction(type) {
      this.$emit('event', {type: `${type}Card`, MemberCard: this.MemberCard})
    }
  },
};
